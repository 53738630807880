import React from "react";

import PropTypes from "prop-types";

import { Paper } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 300,
    padding: '30px 30px',
  },
});

function UserCard(props) {
  const user = props.user;
  const classes = useStyles();

  return (
    <Paper elevation={3} square={false} outlined className={classes.root}>
      <h1>{user.username}</h1>
      Email: {user.email}<br />
      Age: {user.age}<br />
      Gender: {user.gender}<br />
      Race: {user.race}<br />
      Religion: {user.religion}<br />
      Housing: {user.housing}<br />      
    </Paper>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserCard;
